body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Montserrat', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;min-height:100%;color:#33415B}html{height:100%}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}#root{height:100%}

.logo{float:left;width:100px;height:35px}.logo img{height:100%;width:100%;object-fit:contain}.header{position:relative;background:#fff;box-shadow:0 1px 5px rgba(11,11,12,0.12);z-index:2}.header ul{position:relative;z-index:1;float:right;border-width:0}.container{height:100%;width:100%;background-color:#fff}.site-layout-background{height:100%;width:100%}.side-bar{padding:10px 0}.logout{color:black}.ant-menu-horizontal>.ant-menu-item,.ant-menu-horizontal>.ant-menu-submenu{border-bottom:0px}.ant-menu-item{margin:0 !important}.ant-layout{height:100vh;width:195vh}

.red{color:red;font-weight:bold}

.form {
  padding-left: 60px;
  padding-right: 60px;
}
.ant-form-item-explain {
  color: red;
}

.logo-login {
  height: 60%;
  width: 70%;
}

#title {
  -webkit-text-emphasis: bold;
          text-emphasis: bold;
  padding-bottom: 20px;
  color: rgb(103, 100, 100);
}

body {
  /* text-align: center; */
  background-color: rgb(204, 207, 208);
  align-content: center;
  justify-content: center;
  display: flex;
  position: relative;
  background-image: url(/static/media/back.a0daf43e.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

#root {
  /* text-align: center;  */
  align-content: center;
  justify-content: center;
  display: flex;
}

.row {
  min-height: 90%;
  width: 80%;
  position: relative;
  border-radius: 15px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.6);
}

.ant-col-14 {
  max-width: 100%;
}

.divider {
  border-left-style: solid;
  border-left-color: rgb(207, 207, 216);
  border-left-width: 2px;
}

@media only screen and (max-width: 992px) {
  .divider {
    border-left-style: none;
  }
}

.button {
  background-color: rgb(44, 179, 241);
  border-color: rgb(44, 179, 241);
  width: 80%;
  color: white;
  display: block;
}

