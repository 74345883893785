.form {
  padding-left: 60px;
  padding-right: 60px;
}
.ant-form-item-explain {
  color: red;
}

.logo-login {
  height: 60%;
  width: 70%;
}

#title {
  text-emphasis: bold;
  padding-bottom: 20px;
  color: rgb(103, 100, 100);
}

body {
  /* text-align: center; */
  background-color: rgb(204, 207, 208);
  align-content: center;
  justify-content: center;
  display: flex;
  position: relative;
  background-image: url('./back.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

#root {
  /* text-align: center;  */
  align-content: center;
  justify-content: center;
  display: flex;
}

.row {
  min-height: 90%;
  width: 80%;
  position: relative;
  border-radius: 15px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.6);
}

.ant-col-14 {
  max-width: 100%;
}

.divider {
  border-left-style: solid;
  border-left-color: rgb(207, 207, 216);
  border-left-width: 2px;
}

@media only screen and (max-width: 992px) {
  .divider {
    border-left-style: none;
  }
}

.button {
  background-color: rgb(44, 179, 241);
  border-color: rgb(44, 179, 241);
  width: 80%;
  color: white;
  display: block;
}
